<template>
    <div class="reveal">
      <div class="slides">
        <section>
            <div class="container1">
              <div>
                <div class="firstSlideSquare" data-id="square">
                  <h2 id="nameTitle">Sam Wilkinson</h2>
                  <p id="where">Saskatoon SK</p>
                </div>
                <div class="firstSlideImage">
                  <img class="headshotImage" src="../assets/images/headshot.jpg" alt="headshot">
                </div>
              </div>
              <div class="whatIAm">
                <p>Full Stack Developer</p>
              </div>
            </div>
        </section>
        <section>
          <section>
            <div class="container2">
              <div id="secondSlidePhoto" style="text-align: left">
                <img class="peoplesJournal" src="../assets/images/peoplesJournal.jpg" alt="peoplesJournal">
              </div>
              <div class="getToKnowMe" data-id="square">
                <div>
                  <h2 id="gtkm">Get To know me</h2>
                </div>
                <div id="GTKMdetails">
                  <p>Bachelor of Science (B.Sc.) - Computer Science</p>
                  <p>My entire life, I've been influenced by design and computing</p>
                </div>
              </div>
              <div id="secondSlidePhoto" style="text-align: right">
                <img class="stilts" src="../assets/images/stilts.jpg" alt="stilts">
              </div>
            </div>
          </section>
          <section>
            <div class="container3">
              <div class="earlyLife" data-id="square">
                <div class="ELTitle">Early Life</div>
                <div class="ELBody">
                  <ul>
                    <li>Influenced by parents who are designers (Graphic & Interior)</li>
                    <li>Mac user from a very young age</li>
                  </ul>
                  <div class="earlyBird">
                    <img src="../assets/images/earlyBird.jpg" alt="earlyBird">
                  </div>
                </div>
              </div>
              <div class="university" data-id="square">
                <div class="UniTitle">University</div>
                <div class="UniBody">
                  <ul>
                    <li>2013-2019 Bachelor of Science (B.Sc.) - Computer Science</li>
                    <li>Member of SaskInvent, Robotics Club</li>
                    <li>Worked summers at ASL Paving</li>
                  </ul>
                  <div class="scfd">
                    <img src="../assets/images/SCFD.jpg" alt="scfd">
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container4">
              <div class="firstCol">
                <div class="barca1">
                  <img src="../assets/images/barca1.jpg" alt="barca1">
                </div>
                <div class="travelTitle">
                  <h2>Travel</h2>
                </div>
                <div class="barca2">
                  <img src="../assets/images/greece1.jpg" alt="greece1">
                </div>
              </div>
              <div class="secondCol">
                <div class="greece1">
                  <img src="../assets/images/london3.jpg" alt="london3">
                </div>
                <div class="greece2">
                  <img src="../assets/images/greece2.jpg" alt="greece2">
                </div>
              </div>
              <div class="thirdCol">
                <div class="london1">
                  <img src="../assets/images/barca2.jpg" alt="barca2">
                </div>
                <div class="london2">
                  <img src="../assets/images/london1.jpg" alt="london1">
                </div>
                <div class="london3">
                  <img src="../assets/images/london2.jpg" alt="london2">
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container5">
              <div class="PEFirstCol">
                <div class="PEPhoto">
                  <img id="cnTower" src="../assets/images/cnTower.jpg" alt="cnTower">
                </div>
                <div class="profExpTitle">
                  <h2>Professional Experience</h2>
                </div>
              </div>
              <div class="PESecondCol">
                <div class="profExpDesc">
                  <h3>University of Saskatchewan - Programmer Analyst</h3>
                  <ul>
                    <li>February 2020 - October 2021</li>
                    <li>I worked within a collaborative team that provided technology solutions to simplify and solve business needs for various stakeholders across the campus</li>
                    <li>My duties were to improve, update and debug existing solutions; as well as plan for, and develop new applications</li>
                    <li>Technologies used: Javascript, JQuery, React, PHP, Symphony, PL/SQL, Jira, Git, Jenkins, Docker</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section>
          <div class="container6">
            <div class="interestsPhoto">
              <div class="interestsPhoto1">
                <img src="../assets/images/hugMe.jpg" alt="hugMe">
              </div>
              <div class="interestsTitle">
                <h2>
                  <span style="color: #E1BAD5">Interests </span>
                  <span style="color: white">&</span>
                  <br/>
                  <span style="color: #CBE7E2">Hobbies</span>
                </h2>
              </div>
            </div>
            <div class="interestsBody">
              <div class="interestsPhoto2">
                <img src="../assets/images/drizzy.jpg" alt="drizzy">
              </div>
              <div class="interestsBodyDesc">
                <ul>
                  <li>10+ years in competitive sports</li>
                  <li>I now enjoy golfing, weight lifting, music, travel, hiking and skiing</li>
                  <br/>
                  <li>Photography/Videography</li>
                  <li>Cooking</li>
                  <li>Fashion</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container7">
            <div class="whyMe">
              <div class="whyMeTitle">
                <h2>What makes me a cut above...</h2>
              </div>
              <div class="whyMeDesc">
                <ul>
                  <li>Willingness to learn and improve</li>
                  <li>Dedicated and hardworking</li>
                  <li>Organized and Analytical</li>
                  <li>Team Player</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="whitespace"></div>
        </section>
        <section>
          <div class="container8">
            <div class="whyZuTitle">
              <h2>Why <br/> zu?</h2>
            </div>
            <div class="whyZuBody">
              <div class="jobsAndWoz">
                <img id="jobsAndWoz" src="../assets/images/jobsAndWoz.jpg" alt="jobsAndWoz">
              </div>
              <div class="whyZuDesc">
                <p>
                  The position of Web Developer allows me the opportunity to demonstrate and enhance my programming and digital problem solving skills.
                   I also place a heavy emphasis on design and usability when developing applications, and I would take great pride to work for a collaborative, 
                  leading edge Saskatoon company.
                </p>
                <br/>
                <p>
                  This position is a perfect fit for me, because I will be able to demonstrate my technical abilities, combined with my passion for the design process.
                   I am eager to learn in both areas and would love the opportunity to learn from the quality of people that work at zu.
                </p>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</template>

<script>
import Reveal from "../../node_modules/reveal.js/dist/reveal"
// import Markdown from "../../node_modules/reveal.js/plugin/markdown/markdown"


export default {
  mounted() {
    Reveal.initialize({
      // The "normal" size of the presentation, aspect ratio will
      // be preserved when the presentation is scaled to fit different
      // resolutions. Can be specified using percentage units.
      width: "100%",
      height: "100%",

      // Factor of the display size that should remain empty around
      // the content
      margin: 0.02,

      // Bounds for smallest/largest possible scale to apply to content
      minScale: 0.2,
      maxScale: 2.0
    })
  },
  data: () => ({
    
  }),
  methods: {
    
  },
  computed: {
    
  }
}
</script>

<style>
    @import '../../node_modules/reveal.js/dist/reveal.css';
    @import '../../node_modules/reveal.js/dist/theme/white.css';
    @import "../scss/styles.scss";

    /* ****************** 1ST SLIDE ********************** */
    
    .container1 {
      display: grid; 
      grid-template-columns: 80% 20%; 
      grid-template-rows: 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "Content Text";
      height: 100%;
    }

    #where {
      text-align: left;
      width: 50vw;
      margin-top: 60%;
      margin-left: 100px;
      color: #CBE7E2 !important;
      font-family: PoppinsReg !important;
      letter-spacing: -0.09rem;
    }

    #nameTitle {
      position: absolute;
      left: 8%;
      top: 19%;
      color: #E1BAD5 !important;
      font-family: PoppinsReg !important;
      letter-spacing: -0.09rem;
    }

    .firstSlideSquare {
      grid-area: Rectange;
      position: absolute;
      top: -4%;
      left: 0%;
      background-color: black;
      height: 90vh;
      width: 65vw;
      z-index: -1;
    }

    .firstSlideImage {
      text-align: right;
      margin-top: 30%;
    }

    .headshotImage {
        width: 43vh;
        grid-area: Image;
        left: 200%;
    }

    .whatIAm {
      grid-area: Text;
      margin-left: 50px;
      margin-top: 55%;
      font-family: AnonymousPro !important;
      color: black;
    }

    /* ****************** 2ND SLIDE ********************** */
    
    .container2 {  
      display: grid;
      grid-template-columns: 30% 40% 30fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        ". . .";
      height: 100%;
      width: 100%;
    }

    .peoplesJournal, .stilts {
      margin-top: 0px !important;
    }

    .getToKnowMe { 
      text-align: center;
      grid-area: 1 / 2 / 2 / 3;
      height: 100vh;
      top: -10% !important;
      background-color: black;
      margin-top: -3.59%;
    }

    #gtkm {
      color: #FFD23F !important;
      font-family: PoppinsReg !important;
      letter-spacing: -0.09rem;
      margin-top: 45%;
      text-align: center;
    }

    #GTKMdetails {
      color: white !important;
      font-family: AnonymousPro !important;
      font-size: 28px;
      letter-spacing: -0.09rem;
      margin-top: 47%;
      text-align: center;
    }

    #secondSlidePhoto {
      width: auto;
      height: 100vh;
      margin-top: 70%;
    }

    /* ****************** 3RD SLIDE ********************** */

    .container3 {
      display: grid; 
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "earlyLife university";
      width: 100%;
      height: 100vh;
      margin-top: 4%;
    }

    .earlyLife {
      display: grid; 
      grid-template-columns: 1fr; 
      grid-template-rows: 0.2fr 1.8fr;
      gap: 0px 0px; 
      grid-template-areas: 
        "ELTitle"
        "ELBody"; 
      grid-area: earlyLife; 
      height: 78.5%;
      width: 98%;
      background-color: black;
      margin-top: 5%;
    }

    .university {
      display: grid; 
      grid-template-columns: 1fr; 
      grid-template-rows: 0.2fr 1.8fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "UniTitle"
        "UniBody"; 
      justify-self: end;
      grid-area: university; 
      height: 78.5%;
      width: 98%;
      background-color: black;
      margin-top: 5%;
    }

    .ELTitle {
      text-align: left;
      margin-top: 39px;
      margin-left: 30px;
      left: 25%;
      color: #077187 !important;
      font-family: PoppinsReg;
      font-size: 58px;
      font-weight: bold !important;
    }

    .UniTitle {
      text-align: right;
      margin-top: 30px;
      margin-right: 30px;
      color: #00916E;
      font-family: PoppinsReg;
      font-size: 58px;
      font-weight: bold !important;
    }

    .ELBody, .UniBody {
      font-family: AnonymousPro;
      color: white;
      font-size: 28px;
      line-height: 93%;
      margin-top: 20%;
      display:inline-block;
    }

    .ELBody li, .UniBody li {
      margin: 10px 0;
    }

    .earlyBird {
      position: relative;
      height: 100%;
      width: 37vw;
      margin-left: 11%;
      margin-top: 13%;
    }

    .scfd {
      position: relative;
      height: 100%;
      width: 37vw;
      margin-left: 12%;
      margin-top: 10.5%;
    }

    #ELTitle {
      color: #FFD23F !important;
      font-family: PoppinsReg !important;
      letter-spacing: -0.09rem;
      margin-top: 52%;
      text-align: center;
    }

    #thirdSlidePhoto {
      align-content: center;
      text-align: center;
      /* margin-left: 30px; */
    }

    /* ****************** 4TH SLIDE ********************** */

    .container4 {  display: grid;
      grid-template-columns: 1.3fr 0.9fr 0.8fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "firstCol secondCol thirdCol";
      width: 100%;
      height: 100%;
      margin-top: 0%
    }

    .firstCol {  display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1.4fr 0.2fr 1.4fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "barca1"
        "travelTitle"
        "barca2";
      grid-area: firstCol;
      height: 100vh;
      overflow: hidden;
    }

    .travelTitle { 
      grid-area: travelTitle;
    }

    .travelTitle h2 {
      font-family: PoppinsReg;
      color: #292f33 !important;
      float: left;
      margin-left: 20px;
    }

    .barca1 { 
      grid-area: barca1;
    }

    .barca2 { grid-area: barca2; }

    .secondCol {  display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.4fr 1.9fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "greece1"
        "greece2";
      grid-area: secondCol;
      height: 100vh;
      overflow: hidden;
    }

    .greece1 { 
      grid-area: greece1;
      height: 100%;
    }

    .greece2 { grid-area: greece2; }

    .thirdCol {  display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.9fr 1.2fr 0.9fr; 
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "london1"
        "london2"
        "london3";
      grid-area: thirdCol;
      height: 100vh;
      overflow: hidden;
    }

    .london1 { grid-area: london1; }

    .london2 { grid-area: london2; }

    .london3 { grid-area: london3; }

    /* ****************** 5TH SLIDE ********************** */

    .container5 {  display: grid;
      grid-template-columns: 0.9fr 1.1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "PEFirstCol PESecondCol";
      width: 100%;
      height: 100%;
      margin-top: 0%
    }

    .PEFirstCol {
      display: grid; 
      grid-template-columns: 1fr; 
      grid-template-rows: 1.7fr 0.3fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "PEPhoto"
        "profExpTitle"; 
      grid-area: WEFirstCol;
      position: absolute;
      top: 1.5%;
      left: -5%;
      background-color: black;
      height: 85vh;
      width: 50vw;
      z-index: -1;
    }

    .PESecondCol {  display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        ".";
      grid-area: PESecondCol;
    }

    .profExpTitle {
      float: left;
      margin-bottom: 10%;
    }

    .profExpTitle h2{
      font-family: PoppinsReg;
      color: #CBE7E2;
    }

    #cnTower {
      float: right;
      height: 40vh;
    }

    .PEPhoto {
      margin-right: -10%;
      margin-top: 15%;
    }

    .profExpDesc {
      text-align: center;
      margin-left: 15%;
      margin-right: 5%;
      margin-top: 16%;
      line-height: 90%;
    }

    .profExpDesc h3 {
      font-family: PoppinsLight;
      font-weight: bolder;
      font-size: 38px;
      color: #292f33;
    }

    .profExpDesc li {
      font-family: AnonymousPro;
      font-size: 28px;
      margin: 10px 0;
    }

    /* ****************** 6TH SLIDE ********************** */
    .container6 {  display: grid;
      grid-template-columns: 0.7fr 1.3fr;
      grid-template-rows: 1.7fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "interestsPhoto interestsBody";
      background-color: black;
      height: 85vh;
      width: 98vw;
      z-index: -1;
      margin-top: 7%;
    }

    .interestsPhoto {  display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.9fr 1.1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "interestsPhoto1"
        "interestsTitle";
      grid-area: interestsPhoto;
    }

    .interestsTitle {
      grid-area: interestsTitle;
      margin-top: 15%;
    }

    .interestsPhoto1 {
      grid-area: interestsPhoto1;
      height: 45vh;
      margin-top: -17%;
    }

    .interestsBody {  display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.9fr 1.1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "interestsPhoto2"
        "interestsBodyDesc";
      grid-area: interestsBody;
    }

    .interestsPhoto2 {
      grid-area: interestsPhoto2;
      height: 45vh;
      margin-top: -10%;
    }

    .interestsBodyDesc {
      grid-area: interestsBodyDesc;
      font-family: AnonymousPro;
      color: white;
      font-size: 34px;
      margin-left: 19%;
      margin-top: 8%;
    }

    /* ****************** 7TH SLIDE ********************** */

    .container7 {  display: grid;
      grid-template-columns: 1.8fr 0.2fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "whyMe whitespace";
      background-color: black;
      height: 100vh;
      width: 80%;
      z-index: -1;
      margin-top: -1.5%;
      margin-left: -1.5%;
    }

    .whyMe {  display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.4fr 1.6fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "whyMeTitle"
        "whyMeDesc";
      grid-area: whyMe;
      height: 90%;
    }

    .whyMeTitle { grid-area: whyMeTitle; }

    .whyMeTitle h2 {
      font-family: PoppinsReg;
      font-weight: bolder;
      color: #077187;
      margin-top: 15%;
    }

    .whyMeDesc { grid-area: whyMeDesc; }

    .whyMeDesc {
      margin-top: 15%;
      margin-left: -30%;
      font-family: AnonymousPro;
      font-weight: bolder;
      font-size: 38px;
      color: white;
    }


    /* ****************** 8TH SLIDE ********************** */

    .container8 {  display: grid;
      grid-template-columns: 0.5fr 1.5fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "whyZuTitle whyZuBody";
      width: 100%;
      height: 100%;
      margin-top: 0%
    }

    .whyZuTitle { 
      display: flex;
      grid-area: whyZuTitle;
      height: 100%;
      align-items: center;
      margin-left: 30%;
    }

    .whyZuTitle h2 {
      color: black;
      font-family: PoppinsReg;
    }

    .whyZuBody {
      display: grid; 
      grid-template-columns: 0.7fr 1.3fr; 
      grid-template-rows: 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "jobsAndWoz whyZuDesc"; 
      grid-area: whyZuBody;
      background-color: #FFD23F;
      height: 85vh;
      width: 65vw;
      z-index: -1;
    }
    .whyZuDesc {
      font-family: AnonymousPro;
      font-size: 30px;
      color: black;
      margin-top: 10%;
      margin-right: 5%;
    }

    .jobsAndWoz {
      display: flex;
      align-items: center;
    }
    #jobsAndWoz {
      width: 20vw;
      margin-left: -10%;
    }

    
</style>

